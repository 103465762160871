import { AppProps } from 'next/app';
import Head from 'next/head';

import useIsFirstRender from '@polyai/common/hooks/useIsFirstRender';
import { ComponentWithChildren } from '@polyai/common/types/helpers';

export const APP_NAME = 'PolyAI Agent Studio';

const NullFirstRender: ComponentWithChildren = ({ children }) => {
  const isFirstRender = useIsFirstRender();

  if (isFirstRender) {
    return null;
  }

  return <>{children}</>;
};

const SafeHydrate: ComponentWithChildren = ({ children }) => {
  return (
    <div suppressHydrationWarning>
      {typeof window !== 'undefined' && (
        <NullFirstRender>{children}</NullFirstRender>
      )}
    </div>
  );
};

export default function JupiterApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>{APP_NAME}</title>
        <meta
          content="width=device-width, initial-scale=1, maximum-scale=1"
          name="viewport"
        />
      </Head>
      <SafeHydrate>
        <Component {...pageProps} />
      </SafeHydrate>
    </>
  );
}
